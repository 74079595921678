import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {Contract} from '@/model/resource/Contract'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'

export class TokenPriced implements IResource {
  @ResponseSerialize(Contract)
  contract: Contract | null = null

  @ResponseSerialize(BlockchainVersion)
  blockchainVersion: BlockchainVersion | null = null

  idTokenPricedPk: number | null = 0

  title: string | null = null
  imageUrl: string | null = null
  decimals: number | null = 0
  type: string | null = null
  hash: string | null = null
  symbol: string | null = null
  totalSupply: string | null = null
  marketCap: string | null = null
  poolLiquidityUSD: string | null = null
  source: string | null = null
  lastPriceStored: string | null = null
  lastPriceStoredAt: Date | null = null
  isVisible: boolean | null = null
  order: number | null = 0
  isSwappable: boolean | null = null
  active: boolean | null = null
  isDeprecated: boolean | null = null
  createdAt: Date | null = null
  updatedAt: Date | null = null

  get $id() {
    return this.idTokenPricedPk || 0
  }

  get $tag() {
    return String(this.title)
  }

  get priceFormatted() {
    return Number(this.lastPriceStored).toLocaleString('en-US', {
      maximumFractionDigits: 12,
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'USD',
    })
  }

  /**
   * Increments the order of TokenPriced
   */
  async changeOrder() {
    return await Request.post(`/client/token-priced/${this.$id}/order`, this)
      .name('changeOrder')
      .asNumber()
      .getData()
  }

  /**
   * Increments the deprecated status of TokenPriced
   */
  async changeDeprecated() {
    return await Request.post(
      `/client/token-priced/${this.$id}/deprecated`,
      this
    )
      .name('changeDeprecated')
      .asNumber()
      .getData()
  }

  /**
   * Update is active of TokenPriced
   */
  async changeIsVisible() {
    return await Request.post(
      `/client/token-priced/${this.$id}/is-visible`,
      this
    )
      .name('changeIsVisible')
      .asNumber()
      .getData()
  }

  /**
   * Persists a new instance of TokenPriced. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistTokenPriced() {
    return await Request.post(`/client/token-priced`, this)
      .name('persistTokenPriced')
      .asNumber()
      .getData()
  }
}
