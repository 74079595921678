import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {TokenPriced} from '@/model/resource/TokenPriced'
import NetworkTag from '@/components/NetworkTag.vue'
import TokenCellRender from '@/components/render/TokenCellRender.vue'

export class ListTokenPricedSchema extends DefaultSchema {
  readonly name = 'ListTokenPriced'

  readonly fieldSet: FieldSet<TokenPriced> = {
    imageUrl: (schema): FieldComponent => ({
      is: Component.RenderImage,
      bind: {
        src: schema.model.imageUrl,
        alt: this.translateFrom(schema.fieldName),
        innerClass: 'w-32',
      },
    }),
    tokenCellRender: (schema): FieldComponent => ({
      is: TokenCellRender,
      bind: {
        symbol: schema.model.symbol,
        blockchainTagName: schema.model.blockchainVersion?.tagName,
      },
    }),
    poolLiquidityUSD: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.poolLiquidityUSD,
      },
    }),
    lastPriceStored: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.priceFormatted,
      },
    }),
    lastPriceStoredAt: (): FieldComponent => ({
      is: Component.Render,
    }),
    decimals: (): FieldComponent => ({
      is: Component.Render,
    }),
    type: (): FieldComponent => ({
      is: Component.Render,
    }),
    hash: (): FieldComponent => ({
      is: Component.Render,
    }),
    totalSupply: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
