

























































































import _ from 'lodash'
import {Modal} from '@simpli/vue-modal'
import {Component, Mixins, Prop} from 'vue-property-decorator'
import {InputSelect} from '@simpli/vue-input'
import FilterTransactionView from '@/components/filters/FilterTransactionView.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {FilterTokenPricedSchema} from '@/schema/resource/TokenPriced/FilterTokenPricedSchema'
import {TokenPricedCollection} from '@/model/collection/TokenPricedCollection'
import FilterToggle from '@/components/FilterToggle.vue'
import TokenPricedDeprecatedToggleButton from '@/components/TokenPricedDeprecatedToggleButton.vue'
import TokenPricedVisibleToggleButton from '@/components/TokenPricedVisibleToggleButton.vue'

@Component({
  components: {
    TokenPricedVisibleToggleButton,
    TokenPricedDeprecatedToggleButton,
    FilterToggle,
    FilterTransactionView,
    InputSelect,
    Modal,
  },
})
export default class FilterTokenPriced extends Mixins(MixinScreenSize) {
  @Prop({type: Object, required: true}) collection!: TokenPricedCollection

  showModal: boolean = false
  schema = new FilterTokenPricedSchema()

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = [
      this.collection.resource.collectionBlockchainVersion.queryAsPage(),
    ]

    await this.$await.run('softQuery', () => Promise.all(promises))
  }

  async doFilter() {
    this.$emit('submit')
    await this.$await.run('softQuery', () => this.collection.querySearch())
  }

  toggleModal(): void {
    this.showModal = !this.showModal
  }
}
