import { render, staticRenderFns } from "./FilterTokenPriced.vue?vue&type=template&id=bc19896a&scoped=true"
import script from "./FilterTokenPriced.vue?vue&type=script&lang=ts"
export * from "./FilterTokenPriced.vue?vue&type=script&lang=ts"
import style0 from "./FilterTokenPriced.vue?vue&type=style&index=0&id=bc19896a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc19896a",
  null
  
)

export default component.exports